<template>
  <Card>
    <div :class="$style.preliminaryEvaluation">
      <IconAnalysis v-if="mode === 'init'" />
      <IconDeferred v-if="mode === 'loading'" />
      <IconCheck v-if="mode === 'success'" />

      <div :class="$style.content">
        <Text tag="p" weight="semibold">
          <template v-if="isSuccess">
            {{ estimatedCost }} {{ currencyCredits }}
          </template>

          <template v-else>
            {{ t(`${mode}.title`) }}
          </template>
        </Text>

        <Text tag="p" color="secondary" size="s">
          {{ t(`${mode}.subTitle`) }}
        </Text>
      </div>

      <Button
        view="link"
        :class="$style.button"
        :disabled="disabled"
        @click="handleEvaluation"
      >
        {{ t(`${mode}.button`) }}
      </Button>
    </div>
    <div v-if="estimatedCostData" class="preveliminary">
      <Table :data="estimatedCostData" :columns="columns" rowKey="reportId">
        <template #column:phone="{ item }">
          <router-link
            :class="$style.link"
            target="_blank"
            :to="getLinkToJournal(item.phone)"
            >{{ item.phone }}
          </router-link>
        </template>

        <template #column:failureDays="{ item }">
          {{ item.failureDays }}
        </template>

        <template #column:failureInterval="{ item }">
          {{ item.failureInterval }}
        </template>
      </Table>
    </div>
  </Card>
</template>

<script lang="ts" setup>
import {
  Button,
  IconAnalysis,
  IconCheck,
  IconDeferred,
  Table,
  Text,
  toaster,
} from '@smst/ui'
import { computed, ref } from 'vue'
import { useMutation } from 'vue-query'
import { useRoute } from 'vue-router'

import type { SimpleSendSmartForm } from '@/api/__generated__/api.schema'
import Card from '@/components/Card/Card.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { getErrorMessage } from '@/utils/errors'

import { getQuery } from '../../BatchCreate.utils'
import type { SendFormCommonType } from '../../BatchCreate.utils'

const { profile } = useProfile()
const route = useRoute()

const t = useComponentI18n('batchCreate.preliminaryEvaluation')

const accounting = computed(() => profile.value?.accounting)

const currency = computed(() => profile.value?.currencyInfo.currency)

const currencyCredits = computed(() => {
  if (accounting.value === 'credits')
    return t('credits', Number(estimatedCost.value))
  return currency
})

const props = defineProps({
  disabled: Boolean,

  values: {
    type: Object,
  },
})

const {
  isLoading,
  isSuccess,
  mutate: evaluationBatch,
  reset,
} = useMutation(
  (values: SendFormCommonType) => {
    const data = getQuery(values, route)
    return data
  },
  {
    onSuccess: ({ data: { data } }) => {
      if (data && 'estimatedCost' in data) {
        estimatedCost.value = data.estimatedCost
        const total = {
          route: computed(() => t('table.allAmount')),
          price: '',
          amount: data?.estimatedCostData?.total?.totalAmount,
          totalPrice: data?.estimatedCostData?.total?.totalPrice,
        }

        estimatedCostData.value = [...data?.estimatedCostData?.data, total]
      }
    },

    onError: (err) => {
      toaster.error(getErrorMessage(err))
    },
  }
)

const estimatedCost = ref()
const estimatedCostData = ref()

const columns = computed(() => [
  {
    name: t('table.direction'),
    prop: 'route',
    sortable: false,
    wrap: true,
  },
  {
    name: t('table.price'),
    prop: 'price',
    sortable: false,
  },
  {
    name: t('table.size'),
    prop: 'amount',
    sortable: false,
  },
  {
    name: t('table.amount'),
    prop: 'totalPrice',
    sortable: false,
  },
])

const handleEvaluation = computed(() =>
  isLoading.value
    ? reset.value
    : () => {
        evaluationBatch({
          ...props.values,
          calculateCost: true,
        } as SimpleSendSmartForm)
      }
)

type Mode = 'init' | 'loading' | 'success'

const getMode = (): Mode => {
  if (isSuccess.value) return 'success'
  if (isLoading.value) return 'loading'

  return 'init'
}

const mode = computed(() => getMode())
</script>

<style>
.preveliminary table tr:last-child td {
  background-color: var(--color-bg-heading);
}

.preveliminary {
  margin-top: 14px;
}
</style>

<style module>
.preliminaryEvaluation {
  display: flex;
  align-items: center;
}


.content {
  margin-left: var(--gap-16);
}

.button {
  margin-left: auto;
}

</style>
