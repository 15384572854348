import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { i18n } from '@/i18n'

import slide1Image from './images/slide-1.png'
import slide5Image from './images/slide-5.png'
import slide7Image from './images/slide-7.png'

const { t } = i18n.global

type Slide = {
  key: string
  title: string
  image: {
    path: string
    width: number
    height: number
  }
}

export const slidesByMedia: ComputedRef<Slide[]> = computed(() => [
  {
    key: 'slide-1',
    title: t('carousel.slide-1'),
    image: {
      path: slide1Image,
      width: 537,
      height: 372,
    },
  },
  {
    key: 'slide-5',
    title: t('carousel.slide-5'),
    image: {
      path: slide5Image,
      width: 537,
      height: 372,
    },
  },
  {
    key: 'slide-7',
    title: t('carousel.slide-7'),
    image: {
      path: slide7Image,
      width: 537,
      height: 372,
    },
  },
])
